// modules/ConsentManager.js
import config from './config.js';
import { eventEmitter } from './EventEmitter';

class ConsentManager {
    constructor(cookiex) {
        this.cookiex = cookiex;
    }

    async acceptConsentAll() {
        this.cookiex.selectedTheme.theme.consentOptions.forEach(option => {
            option.checked = true;
        });
        await this.acceptConsent();
    }

    async acceptConsent() {
        await this.updateConsentData('accept');

        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Origin", window.location.origin === 'file://'?'https://cookiex.io': window.location.origin);

        const requestOptions = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(this.cookiex.consentData),
            redirect: "follow"
        };

        fetch(`${this.cookiex?.selectedTheme?.consentUrl || config.consentURL}/api/${this.cookiex.selectedTheme.domainName}/domain/${this.cookiex.selectedTheme.domainId}`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(async(data) => {
                localStorage.setItem("ck-session-key", data.sessionKey);
                localStorage.setItem('cookiexConsentAccepted', 'true');
                this.cookiex.uiManager.showConsentIcon();
                this.cookiex.uiManager.removeInitialConsent();
                await this.loadDeferredElements();
                await this.updateThirdPartyConsents();
                await this.fetchLatestConsent(false);
            })
            .catch(error => {
                console.error('Error in consent acceptance:', error);
                alert('There was an issue accepting your consent. Please try again later.');
            });
    }

    async fetchLatestConsent(isWithdraw) {
        const sessionKey = localStorage.getItem("ck-session-key");
        let domainName = this.cookiex.selectedTheme.domainName;
    
        // Ensure both sessionKey and domainName are present
        if (!sessionKey || !domainName) {
            console.warn("Session key or domain name is missing.");
            return;
        }
    
        try {
            // Remove protocol (http:// or https://) if present
            if (domainName.startsWith("http://") || domainName.startsWith("https://")) {
                domainName = domainName.replace(/(^\w+:|^)\/\//, ''); // Remove protocol
            }
    
            // Remove 'www.' prefix if present
            domainName = domainName.replace(/^www\./, "");
    
            // Construct the API URL
            const url = `${this.cookiex?.selectedTheme?.consentUrl || config.consentURL}/api/${domainName}/domain/${sessionKey}`;
    
            // Fetch the consent data
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Failed to fetch consent data: ${response.statusText}`);
            }
    
            const data = await response.json();
    
            // Check if the expected property exists in the response
            if (data && data.dateTime) {
                this.cookiex.selectedTheme.consentTime = data.dateTime;
                eventEmitter.emit('consentTimeUpdated', data.dateTime);
                if(!isWithdraw) {
                    const withdrawBtn = document.querySelector('.cookiex-withdraw-btn');
                    withdrawBtn?.removeAttribute("disabled");
                    withdrawBtn?.removeAttribute("style");
                    withdrawBtn?.classList?.add("cookiex_cmp_reject-filled");
                }
            } else {
                console.warn("Invalid response structure. 'dateTime' not found.");
            }
        } catch (error) {
            console.warn("Error while fetching consent data:", error.message);
        }
    }

    async updateConsent() {
        const sessionKey = localStorage.getItem("ck-session-key");
        if (!sessionKey) {
            await this.acceptConsent();
            return;
        }

        this.updateConsentData('reconsent');

        fetch(`${this.cookiex?.selectedTheme?.consentUrl || config.consentURL}/api/${this.cookiex.selectedTheme.domainName}/domain/${sessionKey}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.cookiex.consentData)
        })
            .then(async(response) => {
                if (response.status === 200) {
                    await this.fetchLatestConsent(false);
                    this.cookiex.uiManager.createUpdateCookieConsent();
                    console.log("Consent successfully updated.");
                }
            })
            .catch(error => console.error('Error updating consent:', error));
    }

    async withdrawConsent() {
        const sessionKey = localStorage.getItem("ck-session-key");
        
        if (!sessionKey) {
            return;
        }

        this.cookiex.selectedTheme.theme.consentOptions.forEach(option => {
            if (option.label !== 'Necessary') {
                option.checked = false;
            }
        });

        await this.updateConsentData('withdraw');

        fetch(`${this.cookiex?.selectedTheme?.consentUrl || config.consentURL}/api/${this.cookiex.selectedTheme.domainName}/domain/${sessionKey}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(async(response) => {
                if (response.status === 200) {
                    const data = await response.json();
                    localStorage.setItem("ck-session-key", data?.sessionKey);
                    const updateConsent = document.querySelector('.cookiex-banner-widget-open');
                    if (updateConsent) {
                        updateConsent.remove();
                    }
                    this.cookiex.uiManager.closeModal();
                    this.cookiex.uiManager.showConsentIcon();
                    await this.fetchLatestConsent(true);
                }
            })
            .catch(error => console.error('Error deleting consent data:', error));
    }

    async rejectConsent() {
        this.cookiex.selectedTheme.theme.consentOptions.forEach(option => {
            if (option.label !== 'Necessary') {
                option.checked = false;
            }
        });

        await this.updateConsentData('withdraw');
        
        fetch(`${this.cookiex?.selectedTheme?.consentUrl || config.consentURL}/api/${this.cookiex.selectedTheme.domainName}/domain/${this.cookiex.selectedTheme.domainId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.cookiex.consentData)
        })
            .then(async(response) => {
                if (response.status === 200) {
                    const data = await response.json();
                    localStorage.setItem("ck-session-key", data?.sessionKey);
                    const updateConsent = document.querySelector('.cookiex-banner-widget-open');
                    if (updateConsent) {
                        updateConsent.remove();
                    }
                    this.cookiex.uiManager.closeModal();
                    this.cookiex.uiManager.showConsentIcon();
                    await this.fetchLatestConsent(false);
                }
            })
            .catch(error => console.error('Error deleting consent data:', error));
    }

    async updateConsentData(action) {
        const timeToConsent = Math.round((Date.now() - this.cookiex.bannerStartTime) / 1000); // Time in seconds
        this.cookiex.consentData = {
            ...this.cookiex.consentData,
            consentUrl: window.location.href,
            consentPreferences: this.cookiex.selectedTheme.theme.consentOptions.find(option => option.label === 'Preferences')?.checked || false,
            consentStatistics: this.cookiex.selectedTheme.theme.consentOptions.find(option => option.label === 'Statistics')?.checked || false,
            consentMarketing: this.cookiex.selectedTheme.theme.consentOptions.find(option => option.label === 'Marketing')?.checked || false,
            consentUnclassified: this.cookiex.selectedTheme.theme.consentOptions.find(option => option.label === 'Unclassified')?.checked || false,
            userIpAnonymized: '192.168.1.1', // Replace with real IP logic if necessary
            userAgent: navigator.userAgent,
            bulkTicket: "abc123", // Optional
            autoConsent: action === 'accept',
            impliedConsent: action === 'accept',
            ipCountry: "US", // Replace with real country logic if necessary
            consentPath: window.location.pathname,
            iabConsent: "", // Optional, replace with real logic
            bannerType: this.cookiex.selectedTheme.layout,
            additionalConsent: this.cookiex.selectedTheme, // Optional, replace with real logic
            timeToConsent: timeToConsent // Replace with the actual time user took to consent
        };
    }

    async loadDeferredElements() {
        this.cookiex.resourceManager.loadDeferredElements();
    }

    async updateThirdPartyConsents() {
        const categories = this.cookiex.selectedTheme.theme.consentOptions.map(e => e.label);
        this.cookiex.gtmManager.updateGTMConsent(categories);
    }
}

export default ConsentManager;
