const env = 'staging'; // Define the environment here

const stagingConfig = {
  apiURL: 'https://staging-cmp-api.staging.cookiex.io',
  fallbackURL: 'https://staging-cmp-api.staging.cookiex.io',
  consentURL: 'https://consent.staging.cookiex.io',
  localesURL: 'https://cdn.cookiex.io/staging/banner/i18n'
};

const productionConfig = {
  apiURL: 'https://api.in.staging.cookiex.io',
  fallbackURL: 'https://api.in.staging.cookiex.io',
  consentURL: 'https://consent.cookiex.io',
  localesURL: 'https://cdn.cookiex.io/banner/i18n'
};

const getConfig = () => {
  switch (env) {
    case 'staging':
      return stagingConfig;
    case 'production':
      return productionConfig;
    default:
      throw new Error(`Unknown environment: ${env}`);
  }
};

const config = getConfig();

export default config;
