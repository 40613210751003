import config from './config.js';

class CookieManager {
    constructor(cookiex) {
        this.cookiex = cookiex;
        this.allCookies = [];
        this.cookiesData = {};
    }

    async fetchCookiesData() {
        try {
            const domainId = this.cookiex.selectedTheme.domainId;
            //const response = await fetch(`https://cdn.cookiex.io/cfg/${domainId.slice(0,2)}/${domainId.slice(2,4)}/${domainId}`);
            const response = await fetch(`${config.apiURL}/domains/${domainId}/cookies`);
            if (!response.ok) throw new Error('Failed to fetch cookies data');

            this.allCookies = await response.json();
            this.cookiesData = this.groupCookiesByCategory(this.allCookies);
        } catch (error) {
            console.error('Error fetching cookies data:', error);
        }
    }

    groupCookiesByCategory(cookies) {
        return cookies.reduce((acc, cookie) => {
            const { category, provider } = cookie;
            acc[category] = acc[category] || {};
            acc[category][provider] = acc[category][provider] || [];
            acc[category][provider].push(cookie);
            return acc;
        }, {});
    }

    isCategoryAllowed(category) {
        const consent = this.cookiex.selectedTheme.theme.consentOptions.find(c => c.label.toLowerCase() === category.toLowerCase());
        return consent ? consent.checked : false;
    }

    async removeUnwantedCookies() {
        const cookieList = document.cookie.split(';').map(cookie => cookie.split('=')[0].trim());
        
        for (const cookieName of cookieList) {
            const cookieData = this.allCookies.find(cookie => cookie.name === cookieName);
            if (cookieData && !this.isCategoryAllowed(cookieData.category)) {
                this.removeCookie({
                    name: cookieName,
                    domain: typeof cookieData.provider === 'string' ? cookieData.provider : null
                });
            }
        }
    }

    removeCookie(cookie) {
        const domain = typeof cookie.domain === 'string' ? `domain=${cookie.domain};` : '';
        document.cookie = `${cookie.name}=; expires=Thu, 01 Jan 1980 00:00:00 UTC; ${domain} path=/;`;
    }

    getCookieDescriptor() {
        let cookieDescriptor = Object.getOwnPropertyDescriptor(document, 'cookie') || Object.getOwnPropertyDescriptor(HTMLDocument.prototype, 'cookie');
        if (!cookieDescriptor) {
            cookieDescriptor = {
                get: HTMLDocument.prototype.__lookupGetter__('cookie'),
                set: HTMLDocument.prototype.__lookupSetter__('cookie'),
            };
        }
        return cookieDescriptor;
    }

    filterLocalCookies() {
        const blacklist = this.allCookies.filter(e => e.category !== 'necessary').map(o => o.name);
        const cookieDescriptor = this.getCookieDescriptor();

        Object.defineProperty(document, "cookie", {
            configurable: true,
            get() {
                return cookieDescriptor.get.apply(document);
            },
            set(value) {
                const cookieName = value.split('=')[0];
                if (!blacklist.some(blacklisted => cookieName.includes(blacklisted))) {
                    cookieDescriptor.set.apply(document, arguments);
                }
            }
        });
    }

    loadDeferredElements() {
        const deferredElements = document.querySelectorAll('[data-src], [data-href]');
        deferredElements.forEach(el => {
            const dataSrc = el.getAttribute('data-src');
            const dataHref = el.getAttribute('data-href');
            const category = el.getAttribute('data-category');
            const blockedBy = el.getAttribute('data-blocked-by');

            if (blockedBy === 'cookiex' && this.isCategoryAllowed(category)) {
                if (dataSrc) {
                    el.setAttribute('src', dataSrc);
                    el.removeAttribute('data-src');
                }
                if (dataHref) {
                    el.setAttribute('href', dataHref);
                    el.removeAttribute('data-href');
                }
                el.removeAttribute('data-blocked-by');
                console.log(`Loading deferred element: ${dataSrc || dataHref} for category: ${category}`);
            } else if (blockedBy === 'cookiex') {
                console.log(`Element in category: ${category} is still blocked by cookiex.`);
            }
        });
    }

    deferResource(element) {
        const srcOrHref = element.src || element.href;
        const currentDomain = window.location.hostname === '127.0.0.1' ? ".rempute.com" : window.location.hostname;

        this.allCookies.forEach(cookie => {
            if (srcOrHref && srcOrHref.includes(cookie.provider) && !currentDomain.includes(cookie.provider)) {
                if (!this.isCategoryAllowed(cookie.category)) {
                    this.deferElement(element, cookie.category);
                }
            }
        });
    }

    deferElement(el, category) {
        const srcOrHref = el.src || el.href;
        if (srcOrHref) {
            if (['SCRIPT', 'IFRAME', 'VIDEO', 'AUDIO'].includes(el.tagName)) {
                el.setAttribute('data-src', el.src);
                el.setAttribute('data-category', category);
                el.setAttribute('data-blocked-by', 'cookiex');
                el.removeAttribute('src');
            } else if (el.tagName === 'LINK' && el.rel === 'stylesheet') {
                el.setAttribute('data-href', el.href);
                el.setAttribute('data-category', category);
                el.setAttribute('data-blocked-by', 'cookiex');
                el.removeAttribute('href');
            } else if (el.tagName === 'IMG') {
                el.setAttribute('data-src', el.src);
                el.setAttribute('data-category', category);
                el.setAttribute('data-blocked-by', 'cookiex');
                el.removeAttribute('src');
            }
            console.log(`Deferred element from provider: ${srcOrHref} (category: ${category})`);
        }
    }

    observeNewResources() {
        const observer = new MutationObserver(mutationsList => {
            mutationsList.forEach(mutation => {
                if (mutation.type === 'childList') {
                    mutation.addedNodes.forEach(node => {
                        if (node.nodeType === 1 && ['SCRIPT', 'IFRAME', 'IMG', 'LINK', 'AUDIO', 'VIDEO'].includes(node.tagName.toUpperCase())) {
                            this.deferResource(node);
                        }
                    });
                }
            });
        });

        observer.observe(document.body, { childList: true, subtree: true });
    }

    overrideCreateElement = () => {
        const originalCreateElement = document.createElement;
        const cookiex = this.cookiex; // Capture reference to cookiex

        document.createElement = (tagName) => {
            const element = originalCreateElement.call(document, tagName);
            if (['script', 'iframe', 'img', 'link', 'audio', 'video'].includes(tagName.toLowerCase())) {
                const srcSetter = Object.getOwnPropertyDescriptor(HTMLScriptElement.prototype, 'src')?.set;

                Object.defineProperty(element, 'src', {
                    set(value) {
                        const category = cookiex.cookieManager.getElementCategory(value);
                        if (category && !cookiex.cookieManager.isCategoryAllowed(category)) {
                            console.log(`Blocking dynamically created element: ${value}, Category: ${category}`);
                            if (['script', 'iframe', 'audio', 'video'].includes(tagName.toLowerCase())) {
                                element.type = 'text/plain';
                            } else if (tagName.toLowerCase() === 'img') {
                                element.setAttribute('data-src', value);
                                element.removeAttribute('src');
                            }
                        } else {
                            srcSetter?.call(element, value);
                        }
                    },
                });
            }
            return element;
        };
    };

    /**
     * Get the category of an element based on its provider.
     */
    getElementCategory(srcOrHref) {
        const cookie = this.allCookies.find(cookie => srcOrHref.includes(cookie.provider));
        return cookie ? cookie.category : 'Unclassified';
    }
}

export default CookieManager;
