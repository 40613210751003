class ResourceManager {
    constructor(cookiex) {
        this.cookiex = cookiex;
    }

    deferAllResources() {
        document.querySelectorAll('script, iframe, img, link, video, audio').forEach(el => {
            this.deferResource(el); // Apply defer logic to already existing elements
        });
    }

    loadDeferredElements() {
        const deferredElements = document.querySelectorAll('[data-src], [data-href]');
        deferredElements.forEach(el => {
            const dataSrc = el.getAttribute('data-src');
            const dataHref = el.getAttribute('data-href');
            const category = el.getAttribute('data-category'); // Get the category
            const blockedBy = el.getAttribute('data-blocked-by'); // Get the blocked-by attribute

            if (blockedBy === 'cookiex' && this.cookiex.cookieManager.isCategoryAllowed(category)) {
                if (dataSrc) {
                    el.setAttribute('src', dataSrc); // Restore src and load element (e.g., script, iframe, video)
                    el.removeAttribute('data-src'); // Remove data-src after setting src
                }

                if (dataHref) {
                    el.setAttribute('href', dataHref); // Restore href for CSS link elements
                    el.removeAttribute('data-href'); // Remove data-href after setting href
                }

                el.removeAttribute('data-blocked-by'); // Remove blocked-by attribute

                console.log(`Loading deferred element: ${dataSrc || dataHref} for category: ${category}`);
            } else if (blockedBy === 'cookiex') {
                console.log(`Element in category: ${category} is still blocked by cookiex.`);
            }
        });
    }

    deferResource(element) {
        const srcOrHref = element.src || element.href;

        this.cookiex.cookieManager.allCookies.forEach(cookie => {
            if (srcOrHref && !this.cookiex.cookieManager.isCategoryAllowed(cookie.category)) {
                const elements = document.querySelectorAll(`[src="${cookie.sourceUrl}"], [href="${cookie.sourceUrl}"]`);
                if(elements) {
                    elements.forEach(el => {
                        if(el) {
                            this.deferElement(el, cookie.category);
                        }
                    });
                }
            }
        });
    }

    deferElement(el, category) {
        const srcOrHref = el.src || el.href;

        if (srcOrHref) {
            if (['SCRIPT'].includes(el.tagName.toUpperCase())) {
                el.setAttribute('data-src', el.src);
                el.setAttribute('data-category', category); // Store category
                el.setAttribute('data-blocked-by', 'cookiex'); // Mark as blocked by CMP
                el.removeAttribute('src'); // Prevent loading

                // Add beforeScriptExecute listener for Firefox
                const beforeScriptExecuteListener = (event) => {
                    if (el.getAttribute('data-blocked-by') === 'cookiex') {
                        event.preventDefault(); // Block script execution
                        console.log(`Blocked script execution for: ${srcOrHref}`);
                    }
                    el.removeEventListener('beforescriptexecute', beforeScriptExecuteListener);
                };

                el.addEventListener('beforescriptexecute', beforeScriptExecuteListener);
            } else if (['IFRAME', 'VIDEO', 'AUDIO'].includes(el.tagName.toUpperCase())) {
                el.setAttribute('data-src', el.src);
                el.setAttribute('data-category', category);
                el.setAttribute('data-blocked-by', 'cookiex');
                el.removeAttribute('src');
            } else if (el.tagName.toUpperCase() === 'LINK' && el.rel === 'stylesheet') {
                el.setAttribute('data-href', el.href);
                el.setAttribute('data-category', category); // Store category
                el.setAttribute('data-blocked-by', 'cookiex'); // Mark as blocked by CMP
                el.removeAttribute('href'); // Prevent loading the CSS
            } else if (el.tagName.toUpperCase() === 'IMG') {
                el.setAttribute('data-src', el.src);
                el.setAttribute('data-category', category); // Store category
                el.setAttribute('data-blocked-by', 'cookiex'); // Mark as blocked by CMP
                el.removeAttribute('src'); // Prevent loading the image
            }
            console.log(`Deferred element from provider: ${srcOrHref} (category: ${category})`);
        }
    }

    observeNewResources() {
        const observer = new MutationObserver((mutationsList) => {
            mutationsList.forEach(mutation => {
                if (mutation.type === 'childList') {
                    mutation.addedNodes.forEach(node => {
                        if (node.nodeType === 1) { // Only process element nodes
                            const tagName = node.tagName.toUpperCase();

                            // Check if the newly added node is a script, iframe, img, link, video, or audio
                            if (['SCRIPT', 'IFRAME', 'IMG', 'LINK', 'VIDEO', 'AUDIO'].includes(tagName)) {
                                this.deferResource(node); // Apply defer logic to this newly added element
                            }
                        }
                    });
                }
            });
        });

        // Start observing the body for changes
        observer.observe(document.body, {
            childList: true, // Look for added/removed nodes
            subtree: true // Observe the entire subtree, not just direct children
        });

        console.log("Started observing DOM for new resources to defer.");
    }

    /**
     * Block cookies based on the consent categories.
     * This method uses the `allCookies` list to selectively allow or block cookies based on category.
     */
    blockCookiesBasedOnConsent() {
        this.cookiex.cookieManager.allCookies.forEach(cookie => {
            if (!this.cookiex.cookieManager.isCategoryAllowed(cookie.category)) {
                this.clearCookie(cookie.name, cookie.path);
                console.log(`Blocked cookie: ${cookie.name} (category: ${cookie.category})`);
            }
        });
    }

    /**
     * Clear a specific cookie by name and path.
     */
    clearCookie(cookieName, path) {
        const domain = `; domain=${window.location.hostname}`;
        const expiry = 'Thu, 01 Jan 1970 00:00:00 GMT'; // Expiry date in the past to delete the cookie

        document.cookie = `${cookieName}=; expires=${expiry}; path=${path || '/'}${domain}`;
        console.log(`Cleared cookie: ${cookieName}`);
    }
}

export default ResourceManager;
