class GTMManager {
    constructor(cookiex) {
        this.cookiex = cookiex;
        this.gtmObserver = null;
    }

    async loadGTM(gtmId) {
        if (!gtmId) {
            console.error("GTM ID is not provided.");
            return;
        }
        
        (function(w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            const f = d.getElementsByTagName(s)[0];
            const j = d.createElement(s), dl = l !== 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', gtmId);

        console.log(`GTM with ID ${gtmId} has been loaded.`);
    }

    async observeAndRemoveGTM() {
        if (this.gtmObserver) {
            this.gtmObserver.disconnect();
        }

        // Create a MutationObserver to observe added elements in the DOM
        this.gtmObserver = new MutationObserver((mutationsList) => {
            mutationsList.forEach(mutation => {
                if (mutation.type === 'childList') {
                    mutation.addedNodes.forEach(node => {
                        if (node.nodeType === 1) { // Only element nodes
                            const tagName = node.tagName.toLowerCase();

                            // If a <script> or <noscript> tag is added, check if it contains GTM
                            if (tagName === 'script' && node.src && node.src.includes('googletagmanager.com')) {
                                node.parentNode.removeChild(node); // Remove the GTM script
                                console.log("Removed dynamically added GTM script:", node.src);
                            } else if (tagName === 'noscript') {
                                const iframe = node.querySelector('iframe[src*="googletagmanager.com"]');
                                if (iframe) {
                                    node.parentNode.removeChild(node); // Remove the GTM noscript
                                    console.log("Removed dynamically added GTM noscript:", iframe.src);
                                }
                            }
                        }
                    });
                }
            });
        });

        // Start observing the body for added child nodes (e.g., <script> and <noscript> tags)
        this.gtmObserver.observe(document.body, {
            childList: true, // Look for added/removed nodes
            subtree: true // Observe the entire subtree
        });

        console.log("Started observing DOM for GTM script injections.");
    }

    disconnectObserver() {
        if (this.gtmObserver) {
            this.gtmObserver.disconnect();
            console.log("Stopped observing DOM for GTM script injections.");
        }
    }

    blockDefaultGTMConfig() {
        if (typeof window.gtag === 'function') {
            const categories = this.cookiex.selectedTheme.theme.consentOptions.map(option => option.label);
            const consentModeStates = this.getConsentStatesByCategories(categories);
            window.gtag('consent', 'default', consentModeStates);
        } else {
            console.warn("gtag is not available for blockDefaultGTMConfig.");
        }
    }

    getConsentStatesByCategories(categories = []) {
        const consentStates = { ...this.cookiex.denied };
        const mapCategoryToType = {
            Necessary: ['functionality_storage', 'security_storage'],
            Preferences: ['personalization_storage'],
            Marketing: ['analytics_storage'],
            Statistics: ['ad_storage', 'ad_user_data', 'ad_personalization'],
        };
        
        categories.forEach(category => {
            mapCategoryToType[category]?.forEach(consentType => {
                if (consentStates[consentType]) {
                    consentStates[consentType] = 'granted';
                }
            });
        });
        
        return consentStates;
    }

    updateGTMConsent(categories = []) {
        if (typeof window.gtag === 'function') {
            const consentModeStates = this.getConsentStatesByCategories(categories);
            window.gtag('consent', 'update', consentModeStates);
        } else {
            console.warn("gtag is not available for updateGTMConsent.");
        }
    }
}

export default GTMManager;
