// Cookiex.js

import ConsentManager from './ConsentManager.js';
import GTMManager from './GTMManager.js';
import ResourceManager from './ResourceManager.js';
import CookieManager from './CookieManager.js';
import config from './config.js';

class Cookiex {
    constructor() {
        this.selectedTheme = undefined;
        this.acceptedConsent = false;
        this.convertedContent = undefined;
        this.ckBanner = undefined;
        this.styles = undefined;
        this.fontFamily = undefined;
        this.fontSize = undefined;
        this.consentData = {};
        this.bannerStartTime = Date.now();
        this.name = 'Cookiex';
        this.cookiesData = [];
        this.allCookies = [];
        // Initialize managers
        this.consentManager = new ConsentManager(this);
        this.uiManager = undefined;
        this.gtmManager = new GTMManager(this);
        this.resourceManager = new ResourceManager(this);
        this.cookieManager = new CookieManager(this);

        this.languageNames = {
            en: 'English',
            hi: 'Hindi',
            bn: 'Bengali',
            tg: 'Telugu',
            mr: 'Marathi',
            ta: 'Tamil',
            ur: 'Urdu',
            gr: 'Gujarati',
            kn: 'Kannada',
            od: 'Odia',
            ml: 'Malayalam',
            pb: 'Punjabi',
            as: 'Assamese'
        };
    }

    async init(theme) {

        if(theme && theme.themeConfigs) {
            await this.processConsentData(null, theme, null);
        } else {     
            if (theme.domainName.includes("http://") || theme.domainName.includes("https://")) {
                theme.domainName = theme.domainName.replace(/(^\w+:|^)\/\//, ''); // Remove protocol from URL
            }

            // Don't use www. in domain names when looking for consent banner config
            theme.domainName = theme.domainName.replace(/^(www\.)/,"");

            //let url = `https://cdn.cookiex.io/cfg/${theme.domainId.slice(0,2)}/${theme.domainId.slice(2,4)}/${theme.domainId}`;
            let url = `${config.apiURL}/domains/${theme.domainId}/banner/config`;

            const sessionKey = localStorage.getItem("ck-session-key");

            if (sessionKey) {
                url = `${config.consentURL}/api/${theme.domainName}/domain/${sessionKey}`;
            }

            try {
                // Try fetching from primary URL
                const response = await fetch(url);
                
                if (!response.ok) 
                    throw new Error("Primary URL failed");

                const data = await response.json();
                await this.processConsentData(data, theme, sessionKey);
            } catch (error) {
                console.warn("Error while getting data URL", error);
            }
        }
    }

    getLanguageNames() {
        return this.languageNames;
    }
    
    getLanguageCodes() {
        return Object.keys(this.languageNames);
    }

    async getLanguage(languageCode) {
        try {
            const response = await fetch(`${config.localesURL}/${this.selectedTheme.template}/locales/in/${languageCode}.json`);
            if (!response.ok) throw new Error(`Failed to load JSON file for language code: ${languageCode}`);
            return await response.json();
        } catch (error) {
            console.error(`Error loading language file: ${languageCode}`, error);
            throw error;
        }
    }
    
    async processConsentData(data, theme, sessionKey) {
        this.selectedTheme = theme?.themeConfigs ? theme?.themeConfigs : sessionKey ? data.additionalConsent : JSON.parse(data.theme);
        this.selectedTheme.domainName = theme.domainName;
        this.selectedTheme.domainId = theme.domainId;
        this.selectedTheme.consentTime = sessionKey ? data.dateTime : '';
        this.selectedTheme.gtmId = theme?.gtmId;
        this.selectedTheme.gtmEnabled = theme?.gtmEnabled;
        this.selectedTheme.language = theme?.language ?? navigator.language ?? navigator.userLanguage ?? this.selectedTheme.language;
        this.selectedTheme.cookiePreferences = theme?.cookiePreferences;
        this.selectedTheme.autoBlockCookies = theme?.autoBlockCookies;
        this.selectedTheme.selectorId = theme.selectorId || '';
        const body = document.querySelector('body');
        this.selectedTheme.isAccepted = localStorage.getItem("ck-cookiex");
        const bodyStyle = window.getComputedStyle(body);
        this.fontFamily = bodyStyle.getPropertyValue("font-family");
        this.fontSize = bodyStyle.getPropertyValue("font-size");
        this.consentData = data || ''; // Store initial data in consentData object

        const isOthersPresent = this.selectedTheme?.theme?.consentOptions?.some(option => option.label === "Unclassified");
        if (!isOthersPresent) {
            this.selectedTheme?.theme?.consentOptions?.push({
                "checked": false,
                "label": "Unclassified"
            });
        }

        const withdrawalStatus = localStorage.getItem('cookiexConsentStatus');
        if (withdrawalStatus === 'withdraw' || withdrawalStatus === 'rejected') {
            this.selectedTheme.theme.consentOptions.forEach(option => {
                if (option.label !== 'Necessary') {
                    option.checked = false; // Uncheck all except "Necessary"
                }
            });
        }

        const context = require.context('./', false, /UIManager-\w+\.js$/);
        const regulation = this.selectedTheme.regulation?.value?.toUpperCase() ?? 'GDPR';
        const modulePath = `./UIManager-${regulation}.js`;
        if (context.keys().includes(modulePath)) {
            const UIManager = await context(modulePath);
            this.uiManager = new UIManager.default(this);
        } else {
            console.error(`UIManager for regulation: ${regulation} not found.`);
        }

        this.selectedTheme.template = regulation.toLowerCase();
        this.build(this.selectedTheme.language); // Show the consent banner if consent not accepted
    }

    async build(languageCode) {
        try {

            await this.cookieManager.fetchCookiesData();
            this.convertedContent = await this.getLanguage(languageCode);
            if(this.selectedTheme.autoBlockCookies && this.selectedTheme.autoBlockCookies === "1") {
                this.resourceManager.deferAllResources();
                this.cookieManager.overrideCreateElement();
                this.cookieManager.filterLocalCookies();
                await this.cookieManager.removeUnwantedCookies();
            }
            
            if(this.selectedTheme.gtmEnabled && this.selectedTheme.gtmId) {
                try {
                    await this.gtmManager.observeAndRemoveGTM();
                    await this.gtmManager.loadGTM(this.selectedTheme.gtmId);
                    this.gtmManager.blockDefaultGTMConfig();
                } catch(error) {
                    console.log("Error in blockDefaultGTMConfig", error);
                }
            }
            
            this.uiManager.initUI();
        } catch (error) {
            console.error("Error in building Cookiex:", error);
        }
    }

    formatConsentTime(inputDate) {
        const correctedDate = inputDate.replace("+00:00:00", "Z");
        const date = new Date(correctedDate);
        if (isNaN(date)) {
            console.error("Invalid date format");
            return;
        }

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${day}-${month}-${year} ${hours}:${minutes}`;
    }
}


export default Cookiex;
